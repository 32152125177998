import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { French } from 'flatpickr/dist/l10n/fr.js'

// Connects to data-controller="flatpickr"
export default class extends Controller {

  static values = {
    enableTime: { type: Boolean, default: false },
    submitOnClose: { type: Boolean, default: false },
    minDate: String,
    maxDate: String,
    minTime: String,
    maxTime: String,
    hours: Array,
    delay: Number,
    policy: String
  }

  connect() {
    this.config = {
      onclose: this.submitForm.bind(this)
    }
    const hours = this.hoursValue
    console.log(hours);
    const delay = this.delayValue

    const now = new Date() // Assurez-vous que now est défini ici

    const minTime = new Date(now.getTime() + delay * 60 * 60 * 1000)
    const currentDayHours = hours[now.getDay()]

    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    const tomorrowHours = hours[tomorrow.getDay()]
    const openingHour = tomorrowHours.opening_hour.slice(11, 16).split(':')
    tomorrow.setHours(openingHour[0], openingHour[1], 0)

    let minDate

    if(this.policyValue === "custom") {
      if(now.getHours() < 13) {
        now.setHours(14,0)
        minDate = now
      } else {
        minDate = tomorrow;
      }
    } else {
      const closingHour = currentDayHours.closing_hour.slice(11, 16).split(':')
      const closingTime = new Date()
      closingTime.setHours(closingHour[0], closingHour[1], 0)
      if (minTime > closingTime) {
        minDate = tomorrow;
      } else {
        minDate = "today"
      }
    }


    // ATTENTION IL RESTE UN PB SI LE DELAI DEPASSE 24 H !! A CORRIGER PROCHAINEMENT

    const timeRestrictions = {};
    hours.forEach((hour, index) => {
      if (hour.open) {
        timeRestrictions[index] = {
          minTime: hour.opening_hour.slice(11, 16),
          maxTime: hour.closing_hour.slice(11, 16)
        };
      }
    });

    flatpickr(this.element, {
      locale: French,
      altInput: true,
      enableTime: true,
      submitOnClose: true,
      dateFormat: "Z",
      altFormat: "d/m H:i",
      time_24hr: true,
      minDate: minDate, // peut être à changer pour demain directement selon condition ?
      maxDate: new Date().fp_incr(10),
      disableMobile: true,

      onOpen: function (selectedDates, dateStr, instance) {
        let dates = []
        const now = new Date() // Assurez-vous que now est défini ici

        hours.forEach((hour) => {
          if (hour.date && hour.open == false) {
            dates.push(hour.date.slice(0, 10).toString())
          }
        })

        // Désactivez les dates dans le tableau dates
        instance.set("disable", dates)
        console.log(instance);
      },

      onChange: function (selectedDates, dateStr, instance) {
        const now = new Date();
        const selectedDate = selectedDates[0];
        const dayOfWeek = selectedDate.getDay();

        const selectedDateString = selectedDate.toISOString().slice(0, 10);
        let isHoliday = false;
        let holidayHours = null;

        hours.forEach((hour) => {
          if (hour.date && hour.date.slice(0, 10) === selectedDateString) {
            isHoliday = true;
            holidayHours = hour;
          }
        });

        if (isHoliday && holidayHours) {
          instance.set("minTime", holidayHours.opening_hour.slice(11, 16));
          instance.set("maxTime", holidayHours.closing_hour.slice(11, 16));
        } else {
          if (this.policyValue === "custom" && selectedDate.toDateString() === now.toDateString() && now.getHours() < 13) {
            instance.set("minTime", "14:00");
            const currentDayHours = hours[now.getDay()];
            instance.set("maxTime", currentDayHours.closing_hour.slice(11, 16).toString());
          } else {
            if (timeRestrictions[dayOfWeek]) {
              const { minTime, maxTime } = timeRestrictions[dayOfWeek];
              instance.set("minTime", minTime);
              instance.set("maxTime", maxTime);
            }
          }
        }
        // Appliquer les restrictions de temps en fonction du jour sélectionné
      }.bind(this),
      onClose: this.submitForm.bind(this)
    })
  }

  submitForm() {
    this.element.closest("form").requestSubmit()
  }
}
