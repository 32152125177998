import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-amount"
export default class extends Controller {
  static targets = ["orderProduct", "amount"]
  connect() {
  }

  updateAmount(e) {
    const selectedOrderProducts = this.orderProductTargets.filter(input => input.checked)
    const newTotalAmountInCents = this.#calculateNewAmount(selectedOrderProducts)
    this.#displayNewAmount(newTotalAmountInCents)
  }

  #calculateNewAmount(selectedOrderProducts) {
    return selectedOrderProducts.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.dataset.totalAmount, 10)
    }, 0)
  }

  #displayNewAmount(totalAmountInCents) {
    const localeAmount = (totalAmountInCents / 100).toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
    })
    this.amountTarget.innerText = localeAmount
  }
}
