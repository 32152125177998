import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cart-countdown"
export default class extends Controller {
  static values = {
    deadline: String
  }
  static targets = [ "counter", "message", "submitBtn", "cartItems" ]

  connect() {
    this.setCountdown();
    this.timeExeeded = false
  }

  setCountdown = () => {
    const now = new Date();
    const deadline = new Date(this.deadlineValue);

    const remainingTime = deadline - now;

    if (remainingTime > 0) {
      const minutes = Math.floor((remainingTime / 1000 / 60) % 60).toString().padStart(2, "0");
      const seconds = Math.floor((remainingTime / 1000) % 60).toString().padStart(2, "0");

      this.counterTarget.textContent = `${minutes}:${seconds}`;
      setTimeout(this.setCountdown, 1000);
    } else {
      this.timeExeeded = true
      this.disableCart()
    }
  }

  disableCart() {
    this.messageTarget.innerHTML = `
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
      Votre panier a expiré!
    `;
    this.messageTarget.classList.remove("bg-info");
    this.messageTarget.style = "background-color: #FAE5D3; color: #8A4C14;";
    this.cartItemsTarget.classList.add("opacity-25");
    this.element.style.pointerEvent = 'none';
    this.submitBtnTarget.setAttribute('disabled', "")
    console.log("Cart expired!");
    return;
  }
}
